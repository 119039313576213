export const s3Config = (type) => ({
    dirName         : "iconnect/" + type,
    bucketName      : 'iconnects3',
    region          : 'us-east-1',
    accessKeyId     : 'AQ416YMO1HMJY20Z65AK',
    secretAccessKey : 'iqiaFEVwQ4cfbtqy8xCjEBCIHfdAnU0BXAOHOTxO',
    bucket_url      : 'https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/invent/public'
});

// region: 'us-east-1',
// credentials: {
//   accessKeyId: "AQ416YMO1HMJY20Z65AK",
//   secretAccessKey: "iqiaFEVwQ4cfbtqy8xCjEBCIHfdAnU0BXAOHOTxO",
// },
// endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080",
// forcePathStyle: true,