import { Row, Col, Radio, Modal, TimePicker, Form, Input, Select, InputNumber } from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/lang";
import moment from 'moment';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const UserFrom = ({ type, path, sectionName, show, hide, data, refresh }) => {
  const api = {
    // userList: apiPath.allUserList,
    eventList: apiPath.eventByVenueId,
    editTable: apiPath.editEvent,
    addEditTable: apiPath.addEditGuest,
    venueList: apiPath.venueList
  }

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [eventType, setEventType] = useState();
  const [venues, setVenues] = useState([]);
  const [events, setEvents] = useState([])

  const getVenues = () => {
    request({
      url: api.venueList,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setVenues(data.data.docs);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  const getEvents = (id) => {
    request({
      url: api.eventList + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setEvents(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  useEffect(() => {
    getVenues();
    if (!data) return;
    getEvents(data.venue_id?._id)
    setEventType(data.deposit)
    console.log(data.check_in_time, 711)
    form.setFieldsValue({
      ...data,
      country: data.country_id,
      venue_id: data.venue_id?.name,
      event_id: data.event_id?.name,
      name: data.user_id?.name,
      check_in_time: moment(data.check_in_time)

    });
    // setCheckIn(moment(data.check_in_time))

  }, [])


  const onCreate = (values) => {
    // setLoading(true);
    console.log(values, 112)
    const payload = {
      max_cost: values.max_cost,
      // deposit: eventType,
      check_in_time:moment(values.check_in_time),
      total_guest: values.male_guest + values.female_guest
    };

    if (data) {

      payload["guest_id"] = data._id;
    }
    request({
      url: api.addEditTable,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };

  


  return (
    <Modal
      width={800}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
      <Form id="create" form={form} initialValues={{}} onFinish={onCreate} layout="vertical">
        <Row gutter={[24, 16]}>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Guest Name"
              name="name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the venue name!" },
                { max: 200, message: "Event Name should not contain more then 200 characters!" },
                { min: 2, message: "Event Name should contain at least 2 characters!" },
              ]}
            >
              <Input disabled autoComplete="off" placeholder="Enter Event Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Venue Name"
              name="venue_id"
              normalize={value => value.trimStart()}
             
              rules={[
                { required: true, message: "Please select venue!" },
              ]}
            >
            <Input disabled/>
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Event Name"
              name="event_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select Event!" },
              ]}
            >
              <Input disabled/>
              {/* <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Event"
                // onChange={(value) => form.setFieldValue("event_id", value)}
                options={events.map(v => {
                  return { label: v?.name, value: v?._id }
                })}
              // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
              /> */}
            </Form.Item>
          </Col>


          <Col span={24} sm={12}>
            <Form.Item
              label="No. of Male Guest"
              name="male_guest"
              rules={[
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter number of guest" />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label="No. of Female Guest"
              name="female_guest"
              rules={[
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter number of guest" />
            </Form.Item>
          </Col>
          {/* <Col span={24} sm={12}>
            <Form.Item
              label="Total Guest"
              name="total_guest"
              rules={[
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter number of guest" />
            </Form.Item>
          </Col> */}
          <Col span={24} sm={12}>
            <Form.Item

              label="Max Cost"
              name="max_cost"
              rules={[
                { required: true, message: "Missing maximum cost" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter maximum cost" />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="Check-In Time"
              name="check_in_time"
              rules={[
                { required: true, message: "Missing time" },
              ]}
            >
              <TimePicker style={{ width: "100%", height: "42px", borderRadius: "6px" }}
                format="HH:mm"
              />    
          </Form.Item>
          </Col>
          {/* <Col span={24} sm={12}>
            <Form.Item

              label="Deposit"
              name="deposit"

            >
              <Radio.Group defaultValue={eventType} onChange={(e) => setEventType(e.target.value)}>
                <Radio value={true}>Advanced</Radio>
                <Radio value={false}>Cash</Radio>
              </Radio.Group>            </Form.Item>
          </Col> */}

        </Row>
      </Form>
    </Modal >
  )
};

export default UserFrom;