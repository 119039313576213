import { Row, Col, Card, Skeleton, Avatar, Table, Modal} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import moment from 'moment';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import lang from "../../helper/lang";

const EventGuest = ({ title, path, show, hide, refresh}) => {
  const sectionName = "Event";
  const routeName = "event";
  
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
    const fetchData = () => {
      request({
        url: apiPath.eventGuest + "/" + path,
        method: 'GET',
        onSuccess: (data) => {
          setLoading(false);
          setList(data.data.data);
        },
        onError: (error) => {
          ShowToast(error, Severty.ERROR)
        }
      })
    }
  
    useEffect(() => {

      fetchData()
    }, [])
  
    const columns = [    
      {
        title: `${lang("Name")}`,
        dataIndex: "name",
        key: "name",
        render: (_, { name, _id }) => {
          return (<a className="cap avatar-text">{name ? name : 'Not Available'}</a>);
        },
        sorter: (a, b) => {
          let nameA = a.name?.toLowerCase();
          let nameB = b.name?.toLowerCase();
          if (nameA < nameB)
            return -1;
          if (nameA > nameB)
            return 1;
          return 0;
        },
      },
      {
        title: `${lang("Total")} ${lang("Guests")}`,
        dataIndex: "total_guest",
        key: "total_guest",
        render: (_, { total_guest }) => {
          return (<a className="cap avatar-text">{total_guest ? total_guest : 0}</a>);
        },
      },
      {
        title: `${lang("Checked")} ${lang("User")}`,
        dataIndex: "checked_user",
        key: "checked_user",
        render: (_, { checked_user}) => {
          return (<a className="cap avatar-text">{checked_user ? checked_user : 0}</a>);
        },
      },
      {
        title: `${lang("CheckIn")} ${lang("Date")}${lang("Time")}`,
        dataIndex: "check_in_time",
        key: "check_in_time",
        render: (_, { check_in_time }) => {
          return (
            moment(check_in_time).format('DD-MMM-YY hh:mm A')
          );
        },
        sorter: (a, b) => {
          let nameA = a.check_in_time;
          let nameB = b.check_in_time;
          if (nameA < nameB)
            return -1;
          if (nameA > nameB)
            return 1;
          return 0;
        },
      }
    ];
  


  return (
    <Modal
      width={800}
      open={show}
      title={title}
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
    <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              
            >

              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true,  showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={fetchData}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal >
  )
};

export default EventGuest;