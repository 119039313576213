import * as XLSX from 'xlsx';
import moment from 'moment';

const DownloadExcel = (data, filename) => {
    const mainHeading   =   filename + ' Excel Data, Export on ' + moment().format('DD-MM-YYYY');
    const workbook      =   XLSX.utils.book_new();
    const worksheet     =   XLSX.utils.json_to_sheet(data);
    // XLSX.utils.sheet_add_aoa(worksheet, [[mainHeading]], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, `${moment().milliseconds() + 1000 * (moment().seconds() + 60 * 60) + `-${filename}`}.xlsx`);
    return true;
};

const SampleFileDownload = (sectionName) => {
    let fileUrl = process.env.PUBLIC_URL;
    let file;

    if (sectionName === 'serviceCategory') {
        fileUrl += '/service_category_sample.xlsx';
        file = 'service_category_sample.xlsx';
    }
    if (sectionName === 'productCategory') {
        fileUrl += '/product_category_sample.xlsx';
        file = 'product_category_sample.xlsx';
    }
    if (sectionName === 'Service') {
        fileUrl += '/service_sample.xlsx';
        file = 'service_sample.xlsx';
    }
    else if (sectionName === 'Brand') {
        fileUrl += '/brand_sample.xlsx';
        file = 'brand_sample.xlsx';
    }
    else if (sectionName === 'Customer') {
        fileUrl += '/user.xlsx';
        file = 'user.xlsx';
    }
    else if (sectionName === 'Dealer') {
        fileUrl += '/user.xlsx';
        file = 'user.xlsx';
    }
    else if (sectionName === 'Specialist') {
        fileUrl += '/user.xlsx';
        file = 'user.xlsx';
    }
    else if (sectionName === 'Vehicle Type') {
        fileUrl += '/vehicle_type_sample.xlsx';
        file = 'user.xlsx';
    }
    else if (sectionName === 'Vehicle Make') {
        fileUrl += '/vehicle_make_sample.xlsx';
        file = 'vehicle_make_sample.xlsx';
    }
    else if (sectionName === 'Vehicle Model') {
        fileUrl += '/vehicle_model_sample.xlsx';
        file = 'vehicle_model_sample.xlsx';
    }
    else if (sectionName === 'Vehicle Variant') {
        fileUrl += '/vehicle_variant_sample.xlsx';
        file = 'vehicle_variant_sample.xlsx';
    }
    else if (sectionName === 'Product') {
        fileUrl += '/product_sample.xlsx';
        file = 'product_sample.xlsx';
    }
    console.log(sectionName,"sectionName");
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = file || 'sample.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export { DownloadExcel, SampleFileDownload };
