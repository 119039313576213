
import { Menu, Button, Modal, Skeleton } from "antd";
import { useState, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { QuestionCircleFilled } from '@ant-design/icons';
import Logo from "../../assets/images/Logo.png";
import moment, { isMoment } from "moment";
import lang from "../../helper/lang";

export const menuItems = [
  {
    key: "Dashboard",
    path: "/dashboard",
    icon: "fas fa-tachometer-alt",
    label: `${lang("Dashboard")}`
  },
  {
    key: "Venue Manager",
    path: "/venue-manager",
    icon: "fas fa-user-cog",
    label: `${lang("Venue")} ${lang("Manager")}`
  },
  {
    key: "Photos Manager",
    path: "/photos",
    label: `${lang("Photos")} ${lang("Manager")}`,
    icon: "fas fa-image",
    
  },
  {
    key: "Tables Manager",
    path: "/table",
    icon: "fab fa-product-hunt",
    label: `${lang("Tables")} ${lang("Manager")}`
  },
  {
    key: "Events Manager",
    path: "/event",
    icon: "fas fa-calendar",
    label: `${lang("Event")} ${lang("Manager")}`
  },
  {
    
        key: "Tickets",
        path: "/payment/tickets",
        icon: "fas fa-ticket-alt",
        label: `${lang("Tickets")}`
  },
  {
    key: "Access Guests",
    path: "/guest-list",
    icon: "fas fa-list-alt",
    label: `${lang("Guest")} ${lang("List")}`
  },
  {
    key: "Manage Scanner Staff",
    path: "/scanner-staff",
    icon: "fas fa-qrcode", 
    label: `${lang("Manage")} ${lang("Scanner")} ${lang("Staff")}`
  }
]


function Sidenav({ color }) {

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { userProfile, logout, setUserProfile } = useContext(AuthContext)
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState('vertical');
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: lang("Logout"),
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: lang("Cancel"),
        content: <Button> {lang("Are_you_sure_you_want_to")} {lang("Logout")} ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const isActiveLink = (pattern, pathname) => {
    const regexPattern = new RegExp(`^${pattern.replace('*', '.*')}$`);
    return regexPattern.test(pathname);
  };

  const renderTitle = (item) => {
    return (
      <>
        <span className="icon"><i className={item.icon}></i></span>
        <span className="label">{item.label}</span>
      </>
    )
  }

  useEffect(() => {
    setLoading(true)
    if (!userProfile) return
    if (userProfile.user_type == 'Vendor') {
      setMenuLinks(menuItems)
      setLoading(false)
      return
    }
    const newArray = menuItems.filter(item => {
      if (item.children) {
        item.children = item.children.filter(child => userProfile.permission?.hasOwnProperty(child.key));
      } else {
        return userProfile.permission?.hasOwnProperty(item.key)
      }
      return item
    });

    const links = newArray.filter(item => {
      if (item?.children?.length) {
        return true
      } else if (!item?.children) {
        return true
      } else {
        return false
      }
    })

    setMenuLinks(links)
    setLoading(false)

  }, [userProfile])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode('inline');
      } else {
        setMenuMode('vertical');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(moment());
    };
    const intervalId = setInterval(updateDateTime, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className="brand-logo text-center">
        <NavLink to="/" className="imgOuter"> <img src={Logo} alt="Logo" /> </NavLink>
        <h3> {currentDateTime.format("YYYY-MM-DD hh:mm a")}</h3>
      </div>

      {
        loading ? [1, 2, 3, 4, 5, 6].map(item => <Skeleton active key={item} />)
          :
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map(item => {
              if (item.children) {
                return (
                  <Menu.SubMenu key={item.key} title={<>
                    <span className="icon"><i className={item.icon}></i></span>
                    <span className="label">{item.label}</span>
                  </>}>
                    {item.children.map(child => (
                      <Menu.Item key={child.key}>
                        <NavLink to={child.path}>{child.label}</NavLink>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }

              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.path}>
                    {renderTitle(item)}
                  </NavLink>
                </Menu.Item>
              );
            })}


            <Menu.Item onClick={showDeleteConfirm}>
              <NavLink to={'/'}>
                <>
                  <span className="icon"><i className="fas fa-sign-out-alt"></i></span>
                  <span className="label"> {lang("Logout")} </span>
                </>
              </NavLink>
            </Menu.Item>

          </Menu>
      }

    </>
  );

}

export default Sidenav;
