import { Row, Col, Card, Table, Button, Input, Tag, Pagination, Tooltip, Avatar, Image, Collapse } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "./AddForm";
import ImportForm from "../../components/ImportForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";

import ConfirmationDelete from "../../components/ConfirmationDelete";
import lang from "../../helper/lang";
const { Panel } = Collapse;
const Search = Input.Search;

function Index() {

  const sectionName = lang("Table");
  const routeName = "table";

  const api = {
    status: apiPath.tableStatus,
    delete: apiPath.deleteTable,
    addEvent: apiPath.addEditEvent,
    list: apiPath.tableList,

    importFile: apiPath.importEvent
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const { confirmDelete } = ConfirmationDelete()
  const [list, setList] = useState([])
  const [exportList, setExport] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const columns = [

    {
      title: 'Table Status',
      key: 'table_status',
      dataIndex: 'table_status',
      render: (_, { table_status, _id }) => {
        return <p onClick={(e) => showConfirm({ record: _id, path: apiPath.manageTableStatus, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>{table_status ? "Booked" : "Available"}</p>
      },
    },

    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (<a className="cap avatar-text" >{name ? name : 'Not Available'}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },


    {
      title: `${lang("Capacity")}`,
      dataIndex: "capicity",
      key: "capicity",
      render: (_, { capicity }) => {
        return (<a className="cap avatar-text">{capicity ? capicity : 'Not Available'}</a>);
      },
    },
    {
      title: `${lang("Min. Cost")}`,
      dataIndex: "min_cost",
      key: "min_cost",
      render: (_, { min_cost }) => {
        return (<a className="cap avatar-text">{min_cost ? min_cost : 'Not Available'}</a>);
      },
    },
    // {
    //   title: `${lang("Max. Cost")}`,
    //   dataIndex: "max_cost",
    //   key: "max_cost",
    //   render: (_, { max_cost }) => {
    //     return (<a className="cap avatar-text">{max_cost ? max_cost : 'Not Available'}</a>);
    //   },
    // },
    {
      title: `${lang("Reservation Fee")}`,
      dataIndex: "reservation_fee",
      key: "reservation_fee",
      render: (_, { reservation_fee }) => {
        return (<a className="cap avatar-text">{reservation_fee ? reservation_fee : 'Not Available'}</a>);
      }
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },

    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      }
    },
    {
      title: `${lang("Action")} List`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>
              <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"update" + routeName}>

              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: api.delete, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>

            {record?.reservations?.length > 0 ? <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip> : ""}

          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
    exportData()
    // fetchAvailableData(pagination)
  }, [refresh, debouncedSearchText])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterVerify = filters ? filters.is_verify : null;
    const filterBooking = [true];

    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const exportData = () => {
    request({
      url: apiPath.exportTable,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setExport(data.data.data)
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }



  const handleChange = (page, pageSize) => {
    fetchData({current:page, pageSize});
  }


  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  console.log(list, "list>>>>>>>>>>>>>")
  const excelData = exportList.map(row => ({
    "Table Name": row?.name ? row?.name : '-',
    "Table Capacity": row?.capicity ? row?.capicity : '-',
    "Minimum Cost": row?.min_cost ? row?.min_cost : '-',
    "Reservation Fee": row?.reservation_fee ? row?.reservation_fee : '-',
    "Customer Name": row?.reservations?.customer_id.name ? row?.reservations?.customer_id.name : '-',
    "Customer Email": row?.reservations?.customer_id.email ? row?.reservations?.customer_id.email : '-',
    "Customer MobileNumber": row?.reservations?.customer_id.country_code && row?.reservations?.customer_id.mobile_number ? "+" + row?.reservations?.customer_id.country_code + " " + row?.reservations?.customer_id.mobile_number : '-',
    "Event Name": row?.eventDetails?.name ? row?.eventDetails?.name : '-',
    "Event Date": row?.eventDetails.recurringDate ? moment(row?.eventDetails?.recurringDate).format('DD-MMM-YYYY') : '-',
    "Event Day": row?.eventDetails.recurringDay ? row?.eventDetails?.recurringDay : '-',
    "Max Male Guest": row?.reservations?.max_male ? row?.reservations?.max_male : '-',
    "Max Female Guest": row?.reservations?.max_female ? row?.reservations?.max_female : '-',
    "Deposit": row?.reservations?.amount ? row?.reservations?.amount : '-',
    "Invoices": row?.reservations?.invoice ? row?.reservations?.invoice : '-',
    "Registered On": moment(row?.reservations?.created_at).format("DD-MM-YYYY")
  }));

  console.log(excelData, 23999)

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Table")} ${lang("Name")}, Event Name, Venue Name`}
                    />
                    {/* <RangePicker disabledDate={(currenPanelt) => current.isAfter(Date.now())} onChange={handleChangeDate} /> */}
                  </div>
                  <div className="button_group">
                    <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName}</Button>
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>

                  </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div>
                <div className="tabled categoryService"> 
                      <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                      >
                        <div className="customPagination">
                          {list.map((item, index) => (
                            <div key={index} className="table-responsive">
                           
                                <Col span={24}>
                                  <Collapse>
                                    <Panel header={item.venue?.name} key={item.venue?._id}>
                                      {item.events?.map((event) => (
                                        <Collapse key={event.event_id} className="mb-3">
                                          <Panel header={event.eventName} key={event.event_id}>
                                            {event.tables.filter((status) => status.table_status == true).length > 0 ?
                                              <div className="table-responsive">
                                                <Table
                                                  loading={loading}
                                                  columns={columns}
                                                  dataSource={event.tables.filter((status) => status.table_status == true)}
                                                  pagination={{
                                                    defaultPageSize: 10,
                                                    responsive: true,
                                                    total: event.tables.length,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ['10', '20', '30', '50']
                                                  }}
                                                  // onChange={handleChange}
                                                  className="ant-border-space"
                                                />
                                              </div> : <h3>There are no tables booked yet!</h3>}
                                            {event.tables.filter((status) => status.table_status == false).length > 0 ? 
                                            <div className="table-responsive">
                                              <Table
                                                loading={loading}
                                                columns={columns}
                                                dataSource={event.tables.filter((status) => status.table_status == false)}
                                                pagination={{
                                                  defaultPageSize: 10,
                                                  responsive: true,
                                                  total: event.tables.length,
                                                  showSizeChanger: true,
                                                  pageSizeOptions: ['10', '20', '30', '50']
                                                }}
                                                // onChange={handleChange}
                                                className="ant-border-space"
                                              />
                                            </div> : <h3>There are no table Available now!</h3>}
                                          </Panel>
                                        </Collapse>
                                      ))}
                                    </Panel>
                                  </Collapse>

                                </Col>
                           
                            </div>
                          ))}
                          <div className="mt-2 float-right">
  <Pagination  onChange={handleChange} {...pagination} defaultCurrent={1} total={pagination.total} />
</div>
                        </div>

                      </Card> 
                </div>
              </div>


            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}
export default Index;
