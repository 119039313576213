import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";
import lang from "../../helper/lang";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const currentYear = new Date().getFullYear();

  return (
    <AntFooter>
      <div className="copyright">
        <div> {lang("Copyright")} © {currentYear}. {lang("All_Rights_Reserved")} </div>
        <div> {lang("Version")} 1.0 </div>
      </div>
    </AntFooter>
  );
}

export default Footer;