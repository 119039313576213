import { Row, Col, Card, Button, Skeleton, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import { PRICE } from "../../constants/conts";
import moment from 'moment';

function View() {

  const params                      =   useParams();
  const { request }                 =   useRequest();
  const [list, setList]             =   useState({});
  const [orderList, setOrderList]   =   useState({});
  const [loading, setLoading]       =   useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewTransaction + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
        setOrderList(data.data.order_id);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const orderColumns = [];

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <>
      <Card title="Transaction Details">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? [1,2].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Transaction ID:</h5>
                  <h6>{list.transaction_id}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Transaction Type:</h5>
                  <h6 className="cap">{list.transaction_type ? list.transaction_type : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Vendor:</h5>
                  <h6>{list.vendor_id && list.vendor_id.name ? <Link className="cap" to={`/user/vendor/view/${list.vendor_id._id}`}>{list.vendor_id.name}</Link> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer:</h5>
                  <h6>{list.customer_id && list.customer_id.name ? <Link className="cap" to={`/user/customer/view/${list.customer_id._id}`}>{list.customer_id.name}</Link> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Driver:</h5>
                  <h6>{list.driver_id && list.driver_id.name ? <Link className="cap" to={`/user/driver/view/${list.driver_id._id}`}>{list.driver_id.name}</Link> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{'Total Amount ('+PRICE +')'}:</h5>
                  <h6>{list.total}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Payment Status:</h5>
                  <h6 className="cap">{list.payment_status === 'success' ? <Badge status="success" text="Success" /> : list.payment_status === 'cancel' ? <Badge status="error" text="Cancel" /> : <Badge status="warning" text="Pending" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Payment Type:</h5>
                  <h6 className="cap">{list.payment_type ? list.payment_type : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Transaction On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-default" to="/transaction/">Back</Link>
                </div>

              </div>
            }
          </Col>
        </Row>
      </Card>

      {/* <Card title="Order Detail" className="mt-3">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Table
                  loading={loading}
                  columns={orderColumns}
                  dataSource={orderList}
                  className="ant-border-space"
                />
              </div>
            }
          </Col>
        </Row>
      </Card> */}

    </>
  );
}


export default View;
