import { Row, Col, Card, Button, Skeleton, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from "moment";
import { LANGUAGE } from "../../config/language";
import lang from "../../helper/lang";

function View() {

  const sectionName           =   lang("Content");
  const routeName             =   "content";

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (slug) => {
    request({
      url: apiPath.viewContent + "/" + slug,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.slug)
  }, [])

  return (
    <Card title={sectionName + ` ${lang('Detail')}`}>
      <Row gutter={16}>
        <Col span={12} xs={24} md={24}>

          {loading ? <Skeleton active /> : 
            <>
              {LANGUAGE.map(langs => (
                <>
                    <Divider orientation="left" orientationMargin={0} className="devider-color">{langs.long}</Divider>

                    <div className="view-inner-cls">
                        <h5>{lang('Name')}:</h5>
                        <h6 className="cap">{ langs.short === 'en' ? list.en_name : langs.short === 'ar' ? list.ar_name : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                        <h5>{lang('Description')}:</h5>
                        <h6 className="cap">{ <p dangerouslySetInnerHTML={{__html: langs.short === 'en' ? list.en_description : langs.short === 'ar' ? list.ar_description : '-'}}></p> }</h6>
                    </div>
                </>
              ))}

              <div className="view-inner-cls">
                <h5>{lang('Status')}:</h5>
                <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>{lang('Created_On')}:</h5>
                <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
              </div>

              <div className="view-inner-cls float-right">
                <Link className="ant-btn ant-btn-primary" to={`/${routeName}/`}>{lang('Back')}</Link>
              </div>
            </>
          }
        </Col>
      </Row>
    </Card>
  );
}

export default View;
