

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { FIREBASECONFIG } from '../constants/conts';

const m_app = initializeApp(FIREBASECONFIG);

const app = firebase.initializeApp(FIREBASECONFIG);

const db = firebase.firestore();

const m_db = getFirestore(m_app);

const realDb = getDatabase(m_app);

export { db, firebase, realDb, m_db, m_app }