import {Row, Col, Card, Button, Input, Form, Skeleton, Divider, Space, Image } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import DescriptionEditor from '../../components/DescriptionEditor'
import SingleImageUpload from "../../components/SingleImageUpload";
import { LANGUAGE } from "../../config/language";

function Edit() {

    const sectionName   =   "Content";
    const routeName     =   "content";
    const api = {
        addEdit  : apiPath.addEditContent,
    }

    const [form]                = Form.useForm();
    const { request }           = useRequest()
    const params                = useParams();
    const [loading, setLoading] = useState(false)
    const [formValue, setFormValue] = useState();
    const navigate              = useNavigate();
    
    const [editoEnValue, setEditoEnValue] =   useState('');
    const [editoArValue, setEditoArValue] =   useState('');

    const [file, setFile] = useState([]);
    const FileType        = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif", "application/pdf"];

    const handleImage = (data) => {
      data.length > 0 ? setFile(data) : setFile([]);
    }

    const handleEditorChange = (data, lang) => {
        switch(lang) {
            case 'en':
                setEditoEnValue(data);
                break;
            case 'ar':
                setEditoArValue(data);
                break;
            default:
                setEditoEnValue(data);
                break;
        }
    }

    const fetchData = (slug) => {
        request({
            url         :  apiPath.viewContent + "/" + slug,
            method      :  'GET',
            onSuccess   :  (data) => {
                form.setFieldsValue(data.data);
                setFormValue(data.data);
                setLoading(false);

                if (data && data.data && data.data.slug !== 'faq') {
                    setEditoEnValue(data.data.en_description);
                    setEditoArValue(data.data.ar_description);
                }
                
            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    const OnUpdate = (values) => {

        if (editoEnValue.trim() === '<p></p>' || editoEnValue.trim() === "") return ShowToast('Please Enter Description in English', Severty.ERROR)

        const payload  =   {
            ...values,
            en_description : editoEnValue,
            ar_description : editoArValue,
            image: file.length > 0 ? file[0].url : '',
        };

        setLoading(true)
        request({
            url: api.addEdit + "/" + params.slug,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate(`/${routeName}`);
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    useEffect(() => {
      setLoading(true)
      fetchData(params.slug)
    }, [])

    return (
        
        <Card title={"Update " + sectionName}>
            
            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :
                
                <Form className="edit-page-wrap colPadding" form={form} onFinish={OnUpdate} autoComplete="off" layout="verticle" name="content_form">
                   
                    {/* <Col span={24} sm={12}>
                        <Form.Item className="mb-3" label="Upload Image" name="image">

                        <div className="mt-1"></div>

                        <SingleImageUpload value={file} fileType={FileType} imageType={'image'} btnName={'Image'} onChange={(data) => handleImage(data)} />
            
                        </Form.Item>
                    </Col> */}
                         
                    <Row gutter={[24, 0]}>

                        {LANGUAGE.map(lang => (
                            <>
                                <Divider orientation="left" orientationMargin={15} className="devider-color">{lang.long}</Divider>

                                <Col span={24} sm={24}>
                                    <Form.Item
                                        className="mb-0"
                                        label='Name'
                                        name={`${lang.short}_name`}
                                        rules={[
                                        {
                                            required: true,
                                            message: `Please enter the name in ${lang.long}!`,
                                        },
                                        {
                                            max: 250,
                                            message: "Name should not contain more than 250 characters!",
                                        },
                                        {
                                            min: 2,
                                            message: "Name should contain at least 2 characters!",
                                        },
                                        ]}
                                        normalize={(value) => value.trimStart()}
                                    >
                                        <Input
                                        autoComplete="off"
                                        placeholder={`Enter Name in ${lang.long}`}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24} sm={24} className="mt-25">
                                    <Form.Item label={`Description (${lang.long})`} name={`${lang.short}_description`}
                                        rules={[
                                            { required: true, message: `Enter Description ${lang.long}!` },
                                        ]}
                                    >
                                        <DescriptionEditor value={editoEnValue} placeholder={`Enter Description in ${lang.long}`} onChange={(data) => handleEditorChange(data, lang.short)} />

                                    </Form.Item>
                                </Col>
                            </>
                        ))}
                    </Row>

                    <Form.Item className="btn-row float-right mb-0">
                        <Link className="ant-btn ant-btn-primary" type="primary" to={`/${routeName}`}>Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    )
}
export default Edit;
